import * as React from "react"
import "./realestate.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import RealEstate from "./components/listings"



const RealEstateListings = () => (
  <Layout>
    <Seo title="Royal Palm Destinations | Royal Palm Destinations Real Estate " />
    <RealEstate/>
  </Layout>
)

export default RealEstateListings;
